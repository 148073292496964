(function() {
	var btnsMenu = document.querySelectorAll('[data-toggle="menu"]');
	var menu     = document.querySelector('.topo__main');
	var topo     = document.querySelector('.topo');
	var active   = 'topo__main--shown';

	btnsMenu.forEach(btn=> {
		btn.rippleCallback = function() {
			menu.classList.add(active);
			document.body.classList.add(active);

			btn.setAttribute('aria-expanded', true);

			backdrop({
				btn: btn,
				target: menu,
				container: topo,
				active: active
			});
		}
	});
})();
