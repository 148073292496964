(()=> {
	const btns 	 = document.querySelectorAll('[data-toggle="search"]');
	const search = document.querySelector('.search');
	const topo   = document.querySelector('.topo');
	const body   = document.body;
	const active = 'search--shown';

	btns.forEach(btn => {
		btn.rippleCallback = function() {
			search.classList.add(active);
			body.classList.add(active);
			btn.setAttribute('aria-expanded', 'true');

			backdrop({
				btn: btn,
				target: search,
				container: topo,
				active: active
			});
		}
	});
})();
