(()=> {
	var html = document.querySelector('html').classList;
	var body = document.body.classList;

	var Acessibilidade = {
		fontState: {
			currentState : 'off',
			ctrls        : document.querySelectorAll('[name="acessibilidade[\'fonts\']"]'),
			apply        : function() {
				Acessibilidade.fontState.ctrls.forEach(btn => {
					if(btn.value === Acessibilidade.fontState.currentState) {
						btn.checked = true;
					} else {
						btn.checked = false;
					}
				});

				if(Acessibilidade.fontState.currentState == 'on') {
					html.add('uppercase');
				}else {
					html.remove('uppercase')
				}
			}
		},
		contrastState: {
			currentState : 'on',
			ctrls        : document.querySelector('#contrast'),
			apply        : function () {
				if(Acessibilidade.contrastState.currentState === 'on') {
					body.add('contrast');
					Acessibilidade.contrastState.ctrls.checked = true;
				}else {
					body.remove('contrast');
					Acessibilidade.contrastState.ctrls.checked = false;
				}
			}
		},
		state  : function(key) {
			var state = localStorage.getItem(key);


			state = state ? state : 'off';

			Acessibilidade[key].currentState = state;
		},
		states : function () {
			Acessibilidade.state('fontState');
			Acessibilidade.state('contrastState');

			Acessibilidade.fontState.apply();
			Acessibilidade.contrastState.apply();
		}
	};

	Acessibilidade.states();

	Acessibilidade.contrastState.ctrls.addEventListener('change', function() {

		if(Acessibilidade.contrastState.ctrls.checked) {
			Acessibilidade.contrastState.currentState = 'on';
			localStorage.setItem('contrastState','on');
		}else {
			Acessibilidade.contrastState.currentState = 'false';
			localStorage.setItem('contrastState','false');
		}

		Acessibilidade.contrastState.apply();
	});

	Acessibilidade.fontState.ctrls.forEach(btn => {
		var value = btn.value;

		btn.addEventListener('change', function() {
			if(btn.checked) {
				Acessibilidade.fontState.currentState = value;
				localStorage.setItem('fontState', value)

				if(value == 'on') {
					html.add('uppercase');
				}

				else {
					html.remove('uppercase')
				}
			}
		});
	});


})();
