(()=> {
	const btnsEnviar = document.querySelectorAll('.btn-enviar');

	btnsEnviar.forEach(btn=> {
		const form = btn.closest('form');

		btn.rippleCallback = function() {
			form.submit();
		}
	});

	const btnsVoltar = document.querySelectorAll('.btn-voltar');

	btnsVoltar.forEach(btn=> {
		btn.rippleCallback = function() {
			history.back();
		}
	});
})();
