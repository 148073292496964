(function() {
    document.addEventListener("copy", function(evt){
        evt.clipboardData.setData("text/plain", "Copiar não é permitido nesta webpage");

        evt.preventDefault();
    }, false);

    document.addEventListener("contextmenu", function(evt){
        evt.preventDefault();
    }, false);

})();
