function btnRipple(btn) {
	btn.addEventListener('click', function (event) {
		event.preventDefault();

		var ripple =  document.createElement('div');

		ripple.classList.add('ripple');
		ripple.style.setProperty('--x', event.offsetX+'px');
		ripple.style.setProperty('--y', event.offsetY+'px');
		ripple.addEventListener('animationend', function() {
			btn.removeChild(ripple);

			if(btn.rippleCallback) btn.rippleCallback();
		});

		btn.appendChild(ripple);
	});
}

const btnsRiple = document.querySelectorAll('.btn-ripple');

for(var i=0, btn; btn=btnsRiple[i]; i++) {
	btnRipple(btn);
}
