window.addEventListener('load', function() {
	const topo   = document.querySelector('.topo');
	const menu   = document.querySelector('.main__menu');
	const faixa  = document.querySelector('.topo__faixa');
	const classe = 'fx';
	let   timer;

	checker();

	function checker() {
		let scrollY = window.scrollY;
		let offset;

		if(midiaSize == 'lg' || midiaSize == 'xl') {
			offset = topo.offsetHeight - (menu.offsetHeight / 2) - 10;
		}else {
			offset = faixa.offsetHeight;
		}

		if(scrollY >= offset) {
			topo.classList.add('fx');
		}else {
			topo.classList.remove('fx');
		}

		topo.style.setProperty('--offset', -offset+'px')

		timer = requestAnimationFrame(checker);
	}
});
