function backdrop({btn, target, container, active}) {
	let backdrop = document.createElement('div');

	backdrop.classList.add('backdrop');

	container.appendChild(backdrop);

	backdrop.addEventListener('click', function() {
		backdrop.classList.add('hide');
		target.classList.remove(active);
		document.body.classList.remove(active);

		backdrop.addEventListener('animationend', function() {
			container.removeChild(backdrop);
		});
	});
}
