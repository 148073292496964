(function() {
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry=> {
			if(entry.isIntersecting) {
				let iframe = entry.target.querySelector('template').innerHTML;

				entry.target.insertAdjacentHTML('beforeend', iframe);

				entry.target.querySelector('iframe').addEventListener('load', function() {
					entry.target.classList.add('loaded');
					entry.target.setAttribute('loaded','');
				});

				observer.unbserve(entry.target);
			}
		}, {
			marginRoot: '200px 200px 200px 200px'
		})
	});
	const lazyEmbeds = document.querySelectorAll('.lazy-embed, .lazy__embed');

	lazyEmbeds.forEach(lazyEmbed => observer.observe(lazyEmbed));

})();
